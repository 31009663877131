@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@100;200;300;400;700&display=swap');
:root{
  --font-primary: "Fira Sans", serif;
  --light-grey: #edeff0;
  --main: #5dbea3;
}

html{
  color: #303233;
}

a{
  text-decoration: none;
  color: inherit;
}

#root {
  display: flex;
  justify-content: center;
}

.page{
  margin-top: 2em;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  max-width: 800px;
  font-family: var(--font-primary);
}

.h2{
  margin: 0;
  font-weight: 300;
  font-size: 28px;
  text-transform: uppercase;
}

.h1{
  font-weight: bold;
  margin: 8px 0;
}

.photo{
  margin-bottom: 16px;
  width: 30%;
}

.skills{
  margin: 16px 16px;
  display: flex;
  justify-content: center;  
  flex-direction: row;
  flex-wrap: wrap;
}

.skill{
  font-size: 18px;
  text-transform: uppercase;
}

.skill__wrapper:not(:last-child){
  margin-right: 16px ;
}

.skill__wrapper{
  background: var(--light-grey);
  margin: 8px 0;
  padding: 8px 16px;
  border-radius: 20px;
}

.grayscale{
  filter: grayscale(1);
}

.grayscale:hover{
  transition: all 300ms;
  filter: grayscale(0);
}

